'use strict';

var cov_1dh3fxgd86 = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/report_calendar/report_calendarCtrl.js',
      hash = '3dfef0082465be6953acf9fbfbdf99204c2bad3f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/report_calendar/report_calendarCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 245,
          column: 3
        }
      },
      '1': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 37
        }
      },
      '2': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 8,
          column: 3
        }
      },
      '3': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 7,
          column: 11
        }
      },
      '4': {
        start: {
          line: 9,
          column: 20
        },
        end: {
          line: 9,
          column: 25
        }
      },
      '5': {
        start: {
          line: 10,
          column: 19
        },
        end: {
          line: 10,
          column: 30
        }
      },
      '6': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '7': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 15,
          column: 4
        }
      },
      '8': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 30,
          column: 7
        }
      },
      '9': {
        start: {
          line: 17,
          column: 23
        },
        end: {
          line: 17,
          column: 32
        }
      },
      '10': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 31
        }
      },
      '11': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 26,
          column: 5
        }
      },
      '12': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 25,
          column: 9
        }
      },
      '13': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 29,
          column: 7
        }
      },
      '14': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 28,
          column: 65
        }
      },
      '15': {
        start: {
          line: 32,
          column: 2
        },
        end: {
          line: 32,
          column: 39
        }
      },
      '16': {
        start: {
          line: 33,
          column: 2
        },
        end: {
          line: 33,
          column: 27
        }
      },
      '17': {
        start: {
          line: 34,
          column: 2
        },
        end: {
          line: 34,
          column: 27
        }
      },
      '18': {
        start: {
          line: 35,
          column: 2
        },
        end: {
          line: 60,
          column: 4
        }
      },
      '19': {
        start: {
          line: 36,
          column: 4
        },
        end: {
          line: 36,
          column: 28
        }
      },
      '20': {
        start: {
          line: 37,
          column: 4
        },
        end: {
          line: 59,
          column: 7
        }
      },
      '21': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 41,
          column: 7
        }
      },
      '22': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 39,
          column: 33
        }
      },
      '23': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 15
        }
      },
      '24': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 46,
          column: 7
        }
      },
      '25': {
        start: {
          line: 43,
          column: 8
        },
        end: {
          line: 43,
          column: 33
        }
      },
      '26': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 45,
          column: 15
        }
      },
      '27': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 51,
          column: 7
        }
      },
      '28': {
        start: {
          line: 48,
          column: 8
        },
        end: {
          line: 48,
          column: 33
        }
      },
      '29': {
        start: {
          line: 49,
          column: 8
        },
        end: {
          line: 49,
          column: 51
        }
      },
      '30': {
        start: {
          line: 50,
          column: 8
        },
        end: {
          line: 50,
          column: 15
        }
      },
      '31': {
        start: {
          line: 53,
          column: 6
        },
        end: {
          line: 58,
          column: 9
        }
      },
      '32': {
        start: {
          line: 54,
          column: 8
        },
        end: {
          line: 54,
          column: 33
        }
      },
      '33': {
        start: {
          line: 55,
          column: 8
        },
        end: {
          line: 57,
          column: 9
        }
      },
      '34': {
        start: {
          line: 56,
          column: 10
        },
        end: {
          line: 56,
          column: 74
        }
      },
      '35': {
        start: {
          line: 61,
          column: 2
        },
        end: {
          line: 61,
          column: 36
        }
      },
      '36': {
        start: {
          line: 62,
          column: 2
        },
        end: {
          line: 62,
          column: 18
        }
      },
      '37': {
        start: {
          line: 63,
          column: 21
        },
        end: {
          line: 63,
          column: 62
        }
      },
      '38': {
        start: {
          line: 65,
          column: 2
        },
        end: {
          line: 67,
          column: 5
        }
      },
      '39': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 66,
          column: 46
        }
      },
      '40': {
        start: {
          line: 69,
          column: 2
        },
        end: {
          line: 89,
          column: 4
        }
      },
      '41': {
        start: {
          line: 77,
          column: 18
        },
        end: {
          line: 77,
          column: 21
        }
      },
      '42': {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 80,
          column: 7
        }
      },
      '43': {
        start: {
          line: 79,
          column: 8
        },
        end: {
          line: 79,
          column: 20
        }
      },
      '44': {
        start: {
          line: 81,
          column: 6
        },
        end: {
          line: 83,
          column: 7
        }
      },
      '45': {
        start: {
          line: 82,
          column: 8
        },
        end: {
          line: 82,
          column: 20
        }
      },
      '46': {
        start: {
          line: 84,
          column: 6
        },
        end: {
          line: 86,
          column: 7
        }
      },
      '47': {
        start: {
          line: 85,
          column: 8
        },
        end: {
          line: 85,
          column: 20
        }
      },
      '48': {
        start: {
          line: 87,
          column: 6
        },
        end: {
          line: 87,
          column: 45
        }
      },
      '49': {
        start: {
          line: 90,
          column: 2
        },
        end: {
          line: 90,
          column: 25
        }
      },
      '50': {
        start: {
          line: 91,
          column: 2
        },
        end: {
          line: 167,
          column: 4
        }
      },
      '51': {
        start: {
          line: 92,
          column: 4
        },
        end: {
          line: 92,
          column: 25
        }
      },
      '52': {
        start: {
          line: 93,
          column: 4
        },
        end: {
          line: 95,
          column: 5
        }
      },
      '53': {
        start: {
          line: 94,
          column: 6
        },
        end: {
          line: 94,
          column: 13
        }
      },
      '54': {
        start: {
          line: 96,
          column: 4
        },
        end: {
          line: 96,
          column: 28
        }
      },
      '55': {
        start: {
          line: 97,
          column: 22
        },
        end: {
          line: 97,
          column: 72
        }
      },
      '56': {
        start: {
          line: 98,
          column: 20
        },
        end: {
          line: 98,
          column: 68
        }
      },
      '57': {
        start: {
          line: 99,
          column: 4
        },
        end: {
          line: 166,
          column: 7
        }
      },
      '58': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 107,
          column: 7
        }
      },
      '59': {
        start: {
          line: 102,
          column: 8
        },
        end: {
          line: 106,
          column: 9
        }
      },
      '60': {
        start: {
          line: 103,
          column: 10
        },
        end: {
          line: 105,
          column: 11
        }
      },
      '61': {
        start: {
          line: 104,
          column: 12
        },
        end: {
          line: 104,
          column: 36
        }
      },
      '62': {
        start: {
          line: 105,
          column: 11
        },
        end: {
          line: 105,
          column: 12
        }
      },
      '63': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 155,
          column: 7
        }
      },
      '64': {
        start: {
          line: 110,
          column: 8
        },
        end: {
          line: 154,
          column: 9
        }
      },
      '65': {
        start: {
          line: 111,
          column: 30
        },
        end: {
          line: 111,
          column: 65
        }
      },
      '66': {
        start: {
          line: 111,
          column: 44
        },
        end: {
          line: 111,
          column: 64
        }
      },
      '67': {
        start: {
          line: 112,
          column: 10
        },
        end: {
          line: 117,
          column: 13
        }
      },
      '68': {
        start: {
          line: 119,
          column: 10
        },
        end: {
          line: 148,
          column: 11
        }
      },
      '69': {
        start: {
          line: 120,
          column: 23
        },
        end: {
          line: 120,
          column: 44
        }
      },
      '70': {
        start: {
          line: 121,
          column: 12
        },
        end: {
          line: 121,
          column: 68
        }
      },
      '71': {
        start: {
          line: 122,
          column: 29
        },
        end: {
          line: 122,
          column: 64
        }
      },
      '72': {
        start: {
          line: 123,
          column: 12
        },
        end: {
          line: 131,
          column: 15
        }
      },
      '73': {
        start: {
          line: 133,
          column: 12
        },
        end: {
          line: 135,
          column: 13
        }
      },
      '74': {
        start: {
          line: 134,
          column: 14
        },
        end: {
          line: 134,
          column: 23
        }
      },
      '75': {
        start: {
          line: 136,
          column: 12
        },
        end: {
          line: 147,
          column: 15
        }
      },
      '76': {
        start: {
          line: 151,
          column: 10
        },
        end: {
          line: 153,
          column: 11
        }
      },
      '77': {
        start: {
          line: 151,
          column: 41
        },
        end: {
          line: 151,
          column: 52
        }
      },
      '78': {
        start: {
          line: 152,
          column: 12
        },
        end: {
          line: 152,
          column: 33
        }
      },
      '79': {
        start: {
          line: 157,
          column: 6
        },
        end: {
          line: 157,
          column: 31
        }
      },
      '80': {
        start: {
          line: 158,
          column: 6
        },
        end: {
          line: 158,
          column: 22
        }
      },
      '81': {
        start: {
          line: 160,
          column: 6
        },
        end: {
          line: 163,
          column: 7
        }
      },
      '82': {
        start: {
          line: 161,
          column: 8
        },
        end: {
          line: 161,
          column: 46
        }
      },
      '83': {
        start: {
          line: 162,
          column: 8
        },
        end: {
          line: 162,
          column: 28
        }
      },
      '84': {
        start: {
          line: 168,
          column: 2
        },
        end: {
          line: 175,
          column: 4
        }
      },
      '85': {
        start: {
          line: 169,
          column: 4
        },
        end: {
          line: 169,
          column: 34
        }
      },
      '86': {
        start: {
          line: 169,
          column: 21
        },
        end: {
          line: 169,
          column: 34
        }
      },
      '87': {
        start: {
          line: 170,
          column: 18
        },
        end: {
          line: 170,
          column: 37
        }
      },
      '88': {
        start: {
          line: 171,
          column: 16
        },
        end: {
          line: 171,
          column: 33
        }
      },
      '89': {
        start: {
          line: 172,
          column: 22
        },
        end: {
          line: 172,
          column: 42
        }
      },
      '90': {
        start: {
          line: 174,
          column: 4
        },
        end: {
          line: 174,
          column: 50
        }
      },
      '91': {
        start: {
          line: 176,
          column: 2
        },
        end: {
          line: 200,
          column: 4
        }
      },
      '92': {
        start: {
          line: 177,
          column: 4
        },
        end: {
          line: 177,
          column: 27
        }
      },
      '93': {
        start: {
          line: 178,
          column: 4
        },
        end: {
          line: 178,
          column: 24
        }
      },
      '94': {
        start: {
          line: 179,
          column: 4
        },
        end: {
          line: 179,
          column: 22
        }
      },
      '95': {
        start: {
          line: 180,
          column: 4
        },
        end: {
          line: 182,
          column: 5
        }
      },
      '96': {
        start: {
          line: 181,
          column: 6
        },
        end: {
          line: 181,
          column: 13
        }
      },
      '97': {
        start: {
          line: 183,
          column: 4
        },
        end: {
          line: 183,
          column: 48
        }
      },
      '98': {
        start: {
          line: 184,
          column: 4
        },
        end: {
          line: 184,
          column: 35
        }
      },
      '99': {
        start: {
          line: 185,
          column: 4
        },
        end: {
          line: 185,
          column: 40
        }
      },
      '100': {
        start: {
          line: 187,
          column: 4
        },
        end: {
          line: 189,
          column: 5
        }
      },
      '101': {
        start: {
          line: 188,
          column: 6
        },
        end: {
          line: 188,
          column: 51
        }
      },
      '102': {
        start: {
          line: 191,
          column: 4
        },
        end: {
          line: 198,
          column: 7
        }
      },
      '103': {
        start: {
          line: 199,
          column: 4
        },
        end: {
          line: 199,
          column: 31
        }
      },
      '104': {
        start: {
          line: 201,
          column: 2
        },
        end: {
          line: 205,
          column: 4
        }
      },
      '105': {
        start: {
          line: 202,
          column: 4
        },
        end: {
          line: 202,
          column: 32
        }
      },
      '106': {
        start: {
          line: 203,
          column: 4
        },
        end: {
          line: 203,
          column: 32
        }
      },
      '107': {
        start: {
          line: 204,
          column: 4
        },
        end: {
          line: 204,
          column: 20
        }
      },
      '108': {
        start: {
          line: 206,
          column: 2
        },
        end: {
          line: 212,
          column: 4
        }
      },
      '109': {
        start: {
          line: 207,
          column: 22
        },
        end: {
          line: 207,
          column: 47
        }
      },
      '110': {
        start: {
          line: 208,
          column: 4
        },
        end: {
          line: 210,
          column: 5
        }
      },
      '111': {
        start: {
          line: 209,
          column: 6
        },
        end: {
          line: 209,
          column: 13
        }
      },
      '112': {
        start: {
          line: 211,
          column: 4
        },
        end: {
          line: 211,
          column: 20
        }
      },
      '113': {
        start: {
          line: 213,
          column: 2
        },
        end: {
          line: 241,
          column: 4
        }
      },
      '114': {
        start: {
          line: 214,
          column: 17
        },
        end: {
          line: 214,
          column: 20
        }
      },
      '115': {
        start: {
          line: 215,
          column: 22
        },
        end: {
          line: 215,
          column: 47
        }
      },
      '116': {
        start: {
          line: 216,
          column: 20
        },
        end: {
          line: 216,
          column: 51
        }
      },
      '117': {
        start: {
          line: 217,
          column: 20
        },
        end: {
          line: 217,
          column: 62
        }
      },
      '118': {
        start: {
          line: 218,
          column: 21
        },
        end: {
          line: 225,
          column: 5
        }
      },
      '119': {
        start: {
          line: 226,
          column: 4
        },
        end: {
          line: 226,
          column: 28
        }
      },
      '120': {
        start: {
          line: 227,
          column: 4
        },
        end: {
          line: 240,
          column: 7
        }
      },
      '121': {
        start: {
          line: 228,
          column: 6
        },
        end: {
          line: 234,
          column: 7
        }
      },
      '122': {
        start: {
          line: 229,
          column: 8
        },
        end: {
          line: 233,
          column: 9
        }
      },
      '123': {
        start: {
          line: 230,
          column: 10
        },
        end: {
          line: 230,
          column: 42
        }
      },
      '124': {
        start: {
          line: 231,
          column: 10
        },
        end: {
          line: 231,
          column: 34
        }
      },
      '125': {
        start: {
          line: 232,
          column: 10
        },
        end: {
          line: 232,
          column: 16
        }
      },
      '126': {
        start: {
          line: 235,
          column: 6
        },
        end: {
          line: 235,
          column: 31
        }
      },
      '127': {
        start: {
          line: 236,
          column: 6
        },
        end: {
          line: 236,
          column: 22
        }
      },
      '128': {
        start: {
          line: 237,
          column: 6
        },
        end: {
          line: 239,
          column: 7
        }
      },
      '129': {
        start: {
          line: 238,
          column: 8
        },
        end: {
          line: 238,
          column: 13
        }
      },
      '130': {
        start: {
          line: 242,
          column: 2
        },
        end: {
          line: 244,
          column: 5
        }
      },
      '131': {
        start: {
          line: 243,
          column: 4
        },
        end: {
          line: 243,
          column: 20
        }
      },
      '132': {
        start: {
          line: 248,
          column: 19
        },
        end: {
          line: 248,
          column: 30
        }
      },
      '133': {
        start: {
          line: 249,
          column: 2
        },
        end: {
          line: 261,
          column: 4
        }
      },
      '134': {
        start: {
          line: 263,
          column: 2
        },
        end: {
          line: 270,
          column: 3
        }
      },
      '135': {
        start: {
          line: 264,
          column: 4
        },
        end: {
          line: 264,
          column: 28
        }
      },
      '136': {
        start: {
          line: 265,
          column: 4
        },
        end: {
          line: 269,
          column: 7
        }
      },
      '137': {
        start: {
          line: 266,
          column: 6
        },
        end: {
          line: 266,
          column: 45
        }
      },
      '138': {
        start: {
          line: 267,
          column: 6
        },
        end: {
          line: 267,
          column: 31
        }
      },
      '139': {
        start: {
          line: 268,
          column: 6
        },
        end: {
          line: 268,
          column: 31
        }
      },
      '140': {
        start: {
          line: 271,
          column: 2
        },
        end: {
          line: 273,
          column: 4
        }
      },
      '141': {
        start: {
          line: 272,
          column: 4
        },
        end: {
          line: 272,
          column: 39
        }
      },
      '142': {
        start: {
          line: 274,
          column: 2
        },
        end: {
          line: 278,
          column: 4
        }
      },
      '143': {
        start: {
          line: 275,
          column: 4
        },
        end: {
          line: 275,
          column: 43
        }
      },
      '144': {
        start: {
          line: 276,
          column: 4
        },
        end: {
          line: 276,
          column: 48
        }
      },
      '145': {
        start: {
          line: 277,
          column: 4
        },
        end: {
          line: 277,
          column: 29
        }
      },
      '146': {
        start: {
          line: 279,
          column: 2
        },
        end: {
          line: 282,
          column: 4
        }
      },
      '147': {
        start: {
          line: 280,
          column: 4
        },
        end: {
          line: 280,
          column: 28
        }
      },
      '148': {
        start: {
          line: 281,
          column: 4
        },
        end: {
          line: 281,
          column: 39
        }
      },
      '149': {
        start: {
          line: 283,
          column: 2
        },
        end: {
          line: 285,
          column: 4
        }
      },
      '150': {
        start: {
          line: 284,
          column: 4
        },
        end: {
          line: 284,
          column: 40
        }
      },
      '151': {
        start: {
          line: 286,
          column: 2
        },
        end: {
          line: 291,
          column: 4
        }
      },
      '152': {
        start: {
          line: 287,
          column: 4
        },
        end: {
          line: 289,
          column: 5
        }
      },
      '153': {
        start: {
          line: 288,
          column: 6
        },
        end: {
          line: 288,
          column: 19
        }
      },
      '154': {
        start: {
          line: 290,
          column: 4
        },
        end: {
          line: 290,
          column: 52
        }
      },
      '155': {
        start: {
          line: 293,
          column: 2
        },
        end: {
          line: 295,
          column: 4
        }
      },
      '156': {
        start: {
          line: 294,
          column: 4
        },
        end: {
          line: 294,
          column: 198
        }
      },
      '157': {
        start: {
          line: 297,
          column: 2
        },
        end: {
          line: 299,
          column: 4
        }
      },
      '158': {
        start: {
          line: 298,
          column: 4
        },
        end: {
          line: 298,
          column: 198
        }
      },
      '159': {
        start: {
          line: 301,
          column: 19
        },
        end: {
          line: 306,
          column: 4
        }
      },
      '160': {
        start: {
          line: 307,
          column: 2
        },
        end: {
          line: 326,
          column: 4
        }
      },
      '161': {
        start: {
          line: 308,
          column: 4
        },
        end: {
          line: 320,
          column: 6
        }
      },
      '162': {
        start: {
          line: 321,
          column: 4
        },
        end: {
          line: 321,
          column: 32
        }
      },
      '163': {
        start: {
          line: 322,
          column: 4
        },
        end: {
          line: 322,
          column: 27
        }
      },
      '164': {
        start: {
          line: 323,
          column: 4
        },
        end: {
          line: 323,
          column: 28
        }
      },
      '165': {
        start: {
          line: 324,
          column: 4
        },
        end: {
          line: 324,
          column: 84
        }
      },
      '166': {
        start: {
          line: 325,
          column: 4
        },
        end: {
          line: 325,
          column: 25
        }
      },
      '167': {
        start: {
          line: 327,
          column: 2
        },
        end: {
          line: 330,
          column: 4
        }
      },
      '168': {
        start: {
          line: 328,
          column: 4
        },
        end: {
          line: 328,
          column: 29
        }
      },
      '169': {
        start: {
          line: 329,
          column: 4
        },
        end: {
          line: 329,
          column: 207
        }
      },
      '170': {
        start: {
          line: 331,
          column: 2
        },
        end: {
          line: 334,
          column: 4
        }
      },
      '171': {
        start: {
          line: 332,
          column: 4
        },
        end: {
          line: 332,
          column: 29
        }
      },
      '172': {
        start: {
          line: 333,
          column: 4
        },
        end: {
          line: 333,
          column: 24
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 56
          },
          end: {
            line: 4,
            column: 57
          }
        },
        loc: {
          start: {
            line: 4,
            column: 232
          },
          end: {
            line: 245,
            column: 1
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 16,
            column: 3
          },
          end: {
            line: 16,
            column: 4
          }
        },
        loc: {
          start: {
            line: 16,
            column: 15
          },
          end: {
            line: 30,
            column: 3
          }
        },
        line: 16
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 27,
            column: 55
          },
          end: {
            line: 27,
            column: 56
          }
        },
        loc: {
          start: {
            line: 27,
            column: 63
          },
          end: {
            line: 29,
            column: 5
          }
        },
        line: 27
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 35,
            column: 24
          },
          end: {
            line: 35,
            column: 25
          }
        },
        loc: {
          start: {
            line: 35,
            column: 40
          },
          end: {
            line: 60,
            column: 3
          }
        },
        line: 35
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 37,
            column: 72
          },
          end: {
            line: 37,
            column: 73
          }
        },
        loc: {
          start: {
            line: 37,
            column: 82
          },
          end: {
            line: 59,
            column: 5
          }
        },
        line: 37
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 53,
            column: 75
          },
          end: {
            line: 53,
            column: 76
          }
        },
        loc: {
          start: {
            line: 53,
            column: 83
          },
          end: {
            line: 58,
            column: 7
          }
        },
        line: 53
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 65,
            column: 26
          },
          end: {
            line: 65,
            column: 27
          }
        },
        loc: {
          start: {
            line: 65,
            column: 32
          },
          end: {
            line: 67,
            column: 3
          }
        },
        line: 65
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 91,
            column: 18
          },
          end: {
            line: 91,
            column: 19
          }
        },
        loc: {
          start: {
            line: 91,
            column: 30
          },
          end: {
            line: 167,
            column: 3
          }
        },
        line: 91
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 99,
            column: 79
          },
          end: {
            line: 99,
            column: 80
          }
        },
        loc: {
          start: {
            line: 99,
            column: 87
          },
          end: {
            line: 166,
            column: 5
          }
        },
        line: 99
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 111,
            column: 39
          },
          end: {
            line: 111,
            column: 40
          }
        },
        loc: {
          start: {
            line: 111,
            column: 44
          },
          end: {
            line: 111,
            column: 64
          }
        },
        line: 111
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 151,
            column: 36
          },
          end: {
            line: 151,
            column: 37
          }
        },
        loc: {
          start: {
            line: 151,
            column: 41
          },
          end: {
            line: 151,
            column: 52
          }
        },
        line: 151
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 168,
            column: 27
          },
          end: {
            line: 168,
            column: 28
          }
        },
        loc: {
          start: {
            line: 168,
            column: 43
          },
          end: {
            line: 175,
            column: 3
          }
        },
        line: 168
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 176,
            column: 16
          },
          end: {
            line: 176,
            column: 17
          }
        },
        loc: {
          start: {
            line: 176,
            column: 32
          },
          end: {
            line: 200,
            column: 3
          }
        },
        line: 176
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 201,
            column: 18
          },
          end: {
            line: 201,
            column: 19
          }
        },
        loc: {
          start: {
            line: 201,
            column: 30
          },
          end: {
            line: 205,
            column: 3
          }
        },
        line: 201
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 206,
            column: 18
          },
          end: {
            line: 206,
            column: 19
          }
        },
        loc: {
          start: {
            line: 206,
            column: 30
          },
          end: {
            line: 212,
            column: 3
          }
        },
        line: 206
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 213,
            column: 20
          },
          end: {
            line: 213,
            column: 21
          }
        },
        loc: {
          start: {
            line: 213,
            column: 34
          },
          end: {
            line: 241,
            column: 3
          }
        },
        line: 213
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 227,
            column: 125
          },
          end: {
            line: 227,
            column: 126
          }
        },
        loc: {
          start: {
            line: 227,
            column: 133
          },
          end: {
            line: 240,
            column: 5
          }
        },
        line: 227
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 242,
            column: 41
          },
          end: {
            line: 242,
            column: 42
          }
        },
        loc: {
          start: {
            line: 242,
            column: 47
          },
          end: {
            line: 244,
            column: 3
          }
        },
        line: 242
      },
      '18': {
        name: 'report_calendarCtrl_edit',
        decl: {
          start: {
            line: 247,
            column: 9
          },
          end: {
            line: 247,
            column: 33
          }
        },
        loc: {
          start: {
            line: 247,
            column: 106
          },
          end: {
            line: 335,
            column: 1
          }
        },
        line: 247
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 265,
            column: 90
          },
          end: {
            line: 265,
            column: 91
          }
        },
        loc: {
          start: {
            line: 265,
            column: 98
          },
          end: {
            line: 269,
            column: 5
          }
        },
        line: 265
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 271,
            column: 30
          },
          end: {
            line: 271,
            column: 31
          }
        },
        loc: {
          start: {
            line: 271,
            column: 52
          },
          end: {
            line: 273,
            column: 3
          }
        },
        line: 271
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 274,
            column: 26
          },
          end: {
            line: 274,
            column: 27
          }
        },
        loc: {
          start: {
            line: 274,
            column: 42
          },
          end: {
            line: 278,
            column: 3
          }
        },
        line: 274
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 279,
            column: 27
          },
          end: {
            line: 279,
            column: 28
          }
        },
        loc: {
          start: {
            line: 279,
            column: 39
          },
          end: {
            line: 282,
            column: 3
          }
        },
        line: 279
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 283,
            column: 22
          },
          end: {
            line: 283,
            column: 23
          }
        },
        loc: {
          start: {
            line: 283,
            column: 34
          },
          end: {
            line: 285,
            column: 3
          }
        },
        line: 283
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 286,
            column: 32
          },
          end: {
            line: 286,
            column: 33
          }
        },
        loc: {
          start: {
            line: 286,
            column: 57
          },
          end: {
            line: 291,
            column: 3
          }
        },
        line: 286
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 293,
            column: 26
          },
          end: {
            line: 293,
            column: 27
          }
        },
        loc: {
          start: {
            line: 293,
            column: 44
          },
          end: {
            line: 295,
            column: 3
          }
        },
        line: 293
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 297,
            column: 22
          },
          end: {
            line: 297,
            column: 23
          }
        },
        loc: {
          start: {
            line: 297,
            column: 40
          },
          end: {
            line: 299,
            column: 3
          }
        },
        line: 297
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 307,
            column: 31
          },
          end: {
            line: 307,
            column: 32
          }
        },
        loc: {
          start: {
            line: 307,
            column: 51
          },
          end: {
            line: 326,
            column: 3
          }
        },
        line: 307
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 327,
            column: 27
          },
          end: {
            line: 327,
            column: 28
          }
        },
        loc: {
          start: {
            line: 327,
            column: 47
          },
          end: {
            line: 330,
            column: 3
          }
        },
        line: 327
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 331,
            column: 25
          },
          end: {
            line: 331,
            column: 26
          }
        },
        loc: {
          start: {
            line: 331,
            column: 37
          },
          end: {
            line: 334,
            column: 3
          }
        },
        line: 331
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }, {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }],
        line: 6
      },
      '1': {
        loc: {
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        }, {
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        }],
        line: 38
      },
      '2': {
        loc: {
          start: {
            line: 42,
            column: 6
          },
          end: {
            line: 46,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 6
          },
          end: {
            line: 46,
            column: 7
          }
        }, {
          start: {
            line: 42,
            column: 6
          },
          end: {
            line: 46,
            column: 7
          }
        }],
        line: 42
      },
      '3': {
        loc: {
          start: {
            line: 47,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 47,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        }, {
          start: {
            line: 47,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        }],
        line: 47
      },
      '4': {
        loc: {
          start: {
            line: 55,
            column: 8
          },
          end: {
            line: 57,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 55,
            column: 8
          },
          end: {
            line: 57,
            column: 9
          }
        }, {
          start: {
            line: 55,
            column: 8
          },
          end: {
            line: 57,
            column: 9
          }
        }],
        line: 55
      },
      '5': {
        loc: {
          start: {
            line: 74,
            column: 15
          },
          end: {
            line: 74,
            column: 127
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 74,
            column: 15
          },
          end: {
            line: 74,
            column: 51
          }
        }, {
          start: {
            line: 74,
            column: 55
          },
          end: {
            line: 74,
            column: 91
          }
        }, {
          start: {
            line: 74,
            column: 95
          },
          end: {
            line: 74,
            column: 127
          }
        }],
        line: 74
      },
      '6': {
        loc: {
          start: {
            line: 75,
            column: 19
          },
          end: {
            line: 75,
            column: 131
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 75,
            column: 19
          },
          end: {
            line: 75,
            column: 55
          }
        }, {
          start: {
            line: 75,
            column: 59
          },
          end: {
            line: 75,
            column: 95
          }
        }, {
          start: {
            line: 75,
            column: 99
          },
          end: {
            line: 75,
            column: 131
          }
        }],
        line: 75
      },
      '7': {
        loc: {
          start: {
            line: 78,
            column: 6
          },
          end: {
            line: 80,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 78,
            column: 6
          },
          end: {
            line: 80,
            column: 7
          }
        }, {
          start: {
            line: 78,
            column: 6
          },
          end: {
            line: 80,
            column: 7
          }
        }],
        line: 78
      },
      '8': {
        loc: {
          start: {
            line: 81,
            column: 6
          },
          end: {
            line: 83,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 81,
            column: 6
          },
          end: {
            line: 83,
            column: 7
          }
        }, {
          start: {
            line: 81,
            column: 6
          },
          end: {
            line: 83,
            column: 7
          }
        }],
        line: 81
      },
      '9': {
        loc: {
          start: {
            line: 84,
            column: 6
          },
          end: {
            line: 86,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 84,
            column: 6
          },
          end: {
            line: 86,
            column: 7
          }
        }, {
          start: {
            line: 84,
            column: 6
          },
          end: {
            line: 86,
            column: 7
          }
        }],
        line: 84
      },
      '10': {
        loc: {
          start: {
            line: 84,
            column: 10
          },
          end: {
            line: 84,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 84,
            column: 10
          },
          end: {
            line: 84,
            column: 28
          }
        }, {
          start: {
            line: 84,
            column: 32
          },
          end: {
            line: 84,
            column: 46
          }
        }],
        line: 84
      },
      '11': {
        loc: {
          start: {
            line: 93,
            column: 4
          },
          end: {
            line: 95,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 93,
            column: 4
          },
          end: {
            line: 95,
            column: 5
          }
        }, {
          start: {
            line: 93,
            column: 4
          },
          end: {
            line: 95,
            column: 5
          }
        }],
        line: 93
      },
      '12': {
        loc: {
          start: {
            line: 93,
            column: 8
          },
          end: {
            line: 93,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 93,
            column: 8
          },
          end: {
            line: 93,
            column: 26
          }
        }, {
          start: {
            line: 93,
            column: 30
          },
          end: {
            line: 93,
            column: 46
          }
        }],
        line: 93
      },
      '13': {
        loc: {
          start: {
            line: 103,
            column: 10
          },
          end: {
            line: 105,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 103,
            column: 10
          },
          end: {
            line: 105,
            column: 11
          }
        }, {
          start: {
            line: 103,
            column: 10
          },
          end: {
            line: 105,
            column: 11
          }
        }],
        line: 103
      },
      '14': {
        loc: {
          start: {
            line: 103,
            column: 14
          },
          end: {
            line: 103,
            column: 104
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 103,
            column: 14
          },
          end: {
            line: 103,
            column: 43
          }
        }, {
          start: {
            line: 103,
            column: 47
          },
          end: {
            line: 103,
            column: 75
          }
        }, {
          start: {
            line: 103,
            column: 79
          },
          end: {
            line: 103,
            column: 104
          }
        }],
        line: 103
      },
      '15': {
        loc: {
          start: {
            line: 109,
            column: 6
          },
          end: {
            line: 155,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 109,
            column: 6
          },
          end: {
            line: 155,
            column: 7
          }
        }, {
          start: {
            line: 109,
            column: 6
          },
          end: {
            line: 155,
            column: 7
          }
        }],
        line: 109
      },
      '16': {
        loc: {
          start: {
            line: 109,
            column: 9
          },
          end: {
            line: 109,
            column: 32
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 109,
            column: 9
          },
          end: {
            line: 109,
            column: 13
          }
        }, {
          start: {
            line: 109,
            column: 17
          },
          end: {
            line: 109,
            column: 32
          }
        }],
        line: 109
      },
      '17': {
        loc: {
          start: {
            line: 130,
            column: 26
          },
          end: {
            line: 130,
            column: 108
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 130,
            column: 26
          },
          end: {
            line: 130,
            column: 66
          }
        }, {
          start: {
            line: 130,
            column: 70
          },
          end: {
            line: 130,
            column: 108
          }
        }],
        line: 130
      },
      '18': {
        loc: {
          start: {
            line: 133,
            column: 12
          },
          end: {
            line: 135,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 133,
            column: 12
          },
          end: {
            line: 135,
            column: 13
          }
        }, {
          start: {
            line: 133,
            column: 12
          },
          end: {
            line: 135,
            column: 13
          }
        }],
        line: 133
      },
      '19': {
        loc: {
          start: {
            line: 133,
            column: 16
          },
          end: {
            line: 133,
            column: 98
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 133,
            column: 16
          },
          end: {
            line: 133,
            column: 56
          }
        }, {
          start: {
            line: 133,
            column: 60
          },
          end: {
            line: 133,
            column: 98
          }
        }],
        line: 133
      },
      '20': {
        loc: {
          start: {
            line: 151,
            column: 10
          },
          end: {
            line: 153,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 151,
            column: 10
          },
          end: {
            line: 153,
            column: 11
          }
        }, {
          start: {
            line: 151,
            column: 10
          },
          end: {
            line: 153,
            column: 11
          }
        }],
        line: 151
      },
      '21': {
        loc: {
          start: {
            line: 160,
            column: 6
          },
          end: {
            line: 163,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 160,
            column: 6
          },
          end: {
            line: 163,
            column: 7
          }
        }, {
          start: {
            line: 160,
            column: 6
          },
          end: {
            line: 163,
            column: 7
          }
        }],
        line: 160
      },
      '22': {
        loc: {
          start: {
            line: 160,
            column: 10
          },
          end: {
            line: 160,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 160,
            column: 10
          },
          end: {
            line: 160,
            column: 29
          }
        }, {
          start: {
            line: 160,
            column: 33
          },
          end: {
            line: 160,
            column: 44
          }
        }],
        line: 160
      },
      '23': {
        loc: {
          start: {
            line: 169,
            column: 4
          },
          end: {
            line: 169,
            column: 34
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 169,
            column: 4
          },
          end: {
            line: 169,
            column: 34
          }
        }, {
          start: {
            line: 169,
            column: 4
          },
          end: {
            line: 169,
            column: 34
          }
        }],
        line: 169
      },
      '24': {
        loc: {
          start: {
            line: 174,
            column: 11
          },
          end: {
            line: 174,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 174,
            column: 11
          },
          end: {
            line: 174,
            column: 29
          }
        }, {
          start: {
            line: 174,
            column: 33
          },
          end: {
            line: 174,
            column: 49
          }
        }],
        line: 174
      },
      '25': {
        loc: {
          start: {
            line: 180,
            column: 4
          },
          end: {
            line: 182,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 180,
            column: 4
          },
          end: {
            line: 182,
            column: 5
          }
        }, {
          start: {
            line: 180,
            column: 4
          },
          end: {
            line: 182,
            column: 5
          }
        }],
        line: 180
      },
      '26': {
        loc: {
          start: {
            line: 187,
            column: 4
          },
          end: {
            line: 189,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 187,
            column: 4
          },
          end: {
            line: 189,
            column: 5
          }
        }, {
          start: {
            line: 187,
            column: 4
          },
          end: {
            line: 189,
            column: 5
          }
        }],
        line: 187
      },
      '27': {
        loc: {
          start: {
            line: 187,
            column: 9
          },
          end: {
            line: 187,
            column: 133
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 187,
            column: 10
          },
          end: {
            line: 187,
            column: 41
          }
        }, {
          start: {
            line: 187,
            column: 47
          },
          end: {
            line: 187,
            column: 75
          }
        }, {
          start: {
            line: 187,
            column: 80
          },
          end: {
            line: 187,
            column: 110
          }
        }, {
          start: {
            line: 187,
            column: 115
          },
          end: {
            line: 187,
            column: 132
          }
        }],
        line: 187
      },
      '28': {
        loc: {
          start: {
            line: 208,
            column: 4
          },
          end: {
            line: 210,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 208,
            column: 4
          },
          end: {
            line: 210,
            column: 5
          }
        }, {
          start: {
            line: 208,
            column: 4
          },
          end: {
            line: 210,
            column: 5
          }
        }],
        line: 208
      },
      '29': {
        loc: {
          start: {
            line: 224,
            column: 10
          },
          end: {
            line: 224,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 224,
            column: 10
          },
          end: {
            line: 224,
            column: 34
          }
        }, {
          start: {
            line: 224,
            column: 38
          },
          end: {
            line: 224,
            column: 39
          }
        }],
        line: 224
      },
      '30': {
        loc: {
          start: {
            line: 229,
            column: 8
          },
          end: {
            line: 233,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 229,
            column: 8
          },
          end: {
            line: 233,
            column: 9
          }
        }, {
          start: {
            line: 229,
            column: 8
          },
          end: {
            line: 233,
            column: 9
          }
        }],
        line: 229
      },
      '31': {
        loc: {
          start: {
            line: 229,
            column: 12
          },
          end: {
            line: 229,
            column: 158
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 229,
            column: 12
          },
          end: {
            line: 229,
            column: 34
          }
        }, {
          start: {
            line: 229,
            column: 38
          },
          end: {
            line: 229,
            column: 76
          }
        }, {
          start: {
            line: 229,
            column: 80
          },
          end: {
            line: 229,
            column: 118
          }
        }, {
          start: {
            line: 229,
            column: 122
          },
          end: {
            line: 229,
            column: 158
          }
        }],
        line: 229
      },
      '32': {
        loc: {
          start: {
            line: 237,
            column: 6
          },
          end: {
            line: 239,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 237,
            column: 6
          },
          end: {
            line: 239,
            column: 7
          }
        }, {
          start: {
            line: 237,
            column: 6
          },
          end: {
            line: 239,
            column: 7
          }
        }],
        line: 237
      },
      '33': {
        loc: {
          start: {
            line: 263,
            column: 2
          },
          end: {
            line: 270,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 263,
            column: 2
          },
          end: {
            line: 270,
            column: 3
          }
        }, {
          start: {
            line: 263,
            column: 2
          },
          end: {
            line: 270,
            column: 3
          }
        }],
        line: 263
      },
      '34': {
        loc: {
          start: {
            line: 263,
            column: 6
          },
          end: {
            line: 263,
            column: 69
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 263,
            column: 6
          },
          end: {
            line: 263,
            column: 28
          }
        }, {
          start: {
            line: 263,
            column: 32
          },
          end: {
            line: 263,
            column: 69
          }
        }],
        line: 263
      },
      '35': {
        loc: {
          start: {
            line: 287,
            column: 4
          },
          end: {
            line: 289,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 287,
            column: 4
          },
          end: {
            line: 289,
            column: 5
          }
        }, {
          start: {
            line: 287,
            column: 4
          },
          end: {
            line: 289,
            column: 5
          }
        }],
        line: 287
      },
      '36': {
        loc: {
          start: {
            line: 287,
            column: 8
          },
          end: {
            line: 287,
            column: 69
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 287,
            column: 8
          },
          end: {
            line: 287,
            column: 43
          }
        }, {
          start: {
            line: 287,
            column: 47
          },
          end: {
            line: 287,
            column: 69
          }
        }],
        line: 287
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0, 0],
      '6': [0, 0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0, 0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0, 0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1dh3fxgd86.s[0]++;
/**
 * Created by BKSB on 08/03/2017.
 */
angular.module('app').controller('report_calendarCtrl', function ($scope, $stateParams, $deltaReportType, $deltaActor, $deltahttp, $window, $deltaDocument, $uibModal, SweetAlert, $rootScope, $translate, $deltadate, $CRUDService, $filter) {
  cov_1dh3fxgd86.f[0]++;
  cov_1dh3fxgd86.s[1]++;

  $rootScope.processPageRight('5_2');
  cov_1dh3fxgd86.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_1dh3fxgd86.b[0][0]++;
    cov_1dh3fxgd86.s[3]++;

    return;
  } else {
    cov_1dh3fxgd86.b[0][1]++;
  }
  var KEY__BASE = (cov_1dh3fxgd86.s[4]++, 'rc_');
  var PATH_DOC = (cov_1dh3fxgd86.s[5]++, 'Documents');
  cov_1dh3fxgd86.s[6]++;
  $scope.init = true;
  cov_1dh3fxgd86.s[7]++;
  $scope.__labels = {
    EDIT: $translate.instant('COMMON.EDIT'),
    DELETE: $translate.instant('COMMON.DELETE')
  };
  cov_1dh3fxgd86.s[8]++;
  (function () {
    cov_1dh3fxgd86.f[1]++;

    var PATH_THEME = (cov_1dh3fxgd86.s[9]++, 'DocType');
    cov_1dh3fxgd86.s[10]++;
    $scope.listAllThPhDoc = [];
    cov_1dh3fxgd86.s[11]++;
    for (var i = 1; i <= 3; i++) {
      cov_1dh3fxgd86.s[12]++;

      $scope.listAllThPhDoc.push({
        CODE_TYPEDOC: '',
        LIBELLE_TYPEDOC: $translate.instant('COMMON.NONE'),
        TYPE_CAT: i,
        id: 0
      });
    }
    cov_1dh3fxgd86.s[13]++;
    $CRUDService.getAll(PATH_THEME, { get: 'all_info' }, function (data) {
      cov_1dh3fxgd86.f[2]++;
      cov_1dh3fxgd86.s[14]++;

      $scope.listAllThPhDoc = $scope.listAllThPhDoc.concat(data);
    });
  })();

  cov_1dh3fxgd86.s[15]++;
  $deltaDocument.addController($scope);
  cov_1dh3fxgd86.s[16]++;
  $scope.listProjects = [];
  cov_1dh3fxgd86.s[17]++;
  $scope.PATH = 'Calendar';
  cov_1dh3fxgd86.s[18]++;
  $scope.downloadItem = function (item) {
    cov_1dh3fxgd86.f[3]++;
    cov_1dh3fxgd86.s[19]++;

    $scope.isloading = true;
    cov_1dh3fxgd86.s[20]++;
    $CRUDService.getAll(PATH_DOC, { get: 'single', id: item.IDRAPPORTS }, function (folder) {
      cov_1dh3fxgd86.f[4]++;
      cov_1dh3fxgd86.s[21]++;

      if (!folder) {
        cov_1dh3fxgd86.b[1][0]++;
        cov_1dh3fxgd86.s[22]++;

        $scope.isloading = false;
        cov_1dh3fxgd86.s[23]++;
        return;
      } else {
        cov_1dh3fxgd86.b[1][1]++;
      }
      cov_1dh3fxgd86.s[24]++;
      if (folder.TYPE_RAPPORT == 1) {
        cov_1dh3fxgd86.b[2][0]++;
        cov_1dh3fxgd86.s[25]++;

        $scope.isloading = false;
        // $state.go('document.preview', {id: folder.id});
        cov_1dh3fxgd86.s[26]++;
        return;
      } else {
        cov_1dh3fxgd86.b[2][1]++;
      }
      cov_1dh3fxgd86.s[27]++;
      if (folder.TYPE_RAPPORT == 2) {
        cov_1dh3fxgd86.b[3][0]++;
        cov_1dh3fxgd86.s[28]++;

        $scope.isloading = false;
        cov_1dh3fxgd86.s[29]++;
        $window.open(folder.CHEMIN_RAPP, '_blank');
        cov_1dh3fxgd86.s[30]++;
        return;
      } else {
        cov_1dh3fxgd86.b[3][1]++;
      }

      cov_1dh3fxgd86.s[31]++;
      $CRUDService.getAll(PATH_DOC, { download: true, c: folder.Reference }, function (data) {
        cov_1dh3fxgd86.f[5]++;
        cov_1dh3fxgd86.s[32]++;

        $scope.isloading = false;
        cov_1dh3fxgd86.s[33]++;
        if (data.f != '') {
          cov_1dh3fxgd86.b[4][0]++;
          cov_1dh3fxgd86.s[34]++;

          $window.open($CRUDService.getServerStrict() + data.f, '_blank');
        } else {
          cov_1dh3fxgd86.b[4][1]++;
        }
      });
    });
  };
  cov_1dh3fxgd86.s[35]++;
  $deltaActor.addController($scope);
  cov_1dh3fxgd86.s[36]++;
  $scope.step = 1;
  var actualYear = (cov_1dh3fxgd86.s[37]++, $deltadate.format(Date.newDate(), 'yyyy'));

  cov_1dh3fxgd86.s[38]++;
  $scope.getActorImage = function (id) {
    cov_1dh3fxgd86.f[6]++;
    cov_1dh3fxgd86.s[39]++;

    return $deltahttp.getActorsRepository(id);
  };

  cov_1dh3fxgd86.s[40]++;
  $scope.choise = {
    begin: Date.newDate(actualYear, 0, 1),
    end: Date.newDate(actualYear, 11, 31),
    editItem: null,
    editing: false,
    only_plan: (cov_1dh3fxgd86.b[5][0]++, localStorage.getItem(KEY__BASE) == 1) || (cov_1dh3fxgd86.b[5][1]++, localStorage.getItem(KEY__BASE) == 3) || (cov_1dh3fxgd86.b[5][2]++, !localStorage.getItem(KEY__BASE)),
    only_not_plan: (cov_1dh3fxgd86.b[6][0]++, localStorage.getItem(KEY__BASE) == 2) || (cov_1dh3fxgd86.b[6][1]++, localStorage.getItem(KEY__BASE) == 3) || (cov_1dh3fxgd86.b[6][2]++, !localStorage.getItem(KEY__BASE)),
    changeplanState: function changeplanState() {
      var value = (cov_1dh3fxgd86.s[41]++, '0');
      cov_1dh3fxgd86.s[42]++;
      if (this.only_not_plan) {
        cov_1dh3fxgd86.b[7][0]++;
        cov_1dh3fxgd86.s[43]++;

        value = '2';
      } else {
        cov_1dh3fxgd86.b[7][1]++;
      }
      cov_1dh3fxgd86.s[44]++;
      if (this.only_plan) {
        cov_1dh3fxgd86.b[8][0]++;
        cov_1dh3fxgd86.s[45]++;

        value = '1';
      } else {
        cov_1dh3fxgd86.b[8][1]++;
      }
      cov_1dh3fxgd86.s[46]++;
      if ((cov_1dh3fxgd86.b[10][0]++, this.only_not_plan) && (cov_1dh3fxgd86.b[10][1]++, this.only_plan)) {
        cov_1dh3fxgd86.b[9][0]++;
        cov_1dh3fxgd86.s[47]++;

        value = '3';
      } else {
        cov_1dh3fxgd86.b[9][1]++;
      }
      cov_1dh3fxgd86.s[48]++;
      localStorage.setItem(KEY__BASE, value);
    }
  };
  cov_1dh3fxgd86.s[49]++;
  $scope.invitation = {};
  cov_1dh3fxgd86.s[50]++;
  $scope.getAll = function () {
    var _this = this;

    cov_1dh3fxgd86.f[7]++;
    cov_1dh3fxgd86.s[51]++;

    $scope.listData = [];
    cov_1dh3fxgd86.s[52]++;
    if ((cov_1dh3fxgd86.b[12][0]++, !this.choise.begin) || (cov_1dh3fxgd86.b[12][1]++, !this.choise.end)) {
      cov_1dh3fxgd86.b[11][0]++;
      cov_1dh3fxgd86.s[53]++;

      return;
    } else {
      cov_1dh3fxgd86.b[11][1]++;
    }
    cov_1dh3fxgd86.s[54]++;
    $scope.isloading = true;
    var beginDate = (cov_1dh3fxgd86.s[55]++, $deltadate.format(this.choise.begin, 'yyyy-mm-dd'));
    var endDate = (cov_1dh3fxgd86.s[56]++, $deltadate.format(this.choise.end, 'yyyy-mm-dd'));
    cov_1dh3fxgd86.s[57]++;
    $CRUDService.getAll($scope.PATH, { get: 'all_p', b: beginDate, e: endDate }, function (data) {
      cov_1dh3fxgd86.f[8]++;
      cov_1dh3fxgd86.s[58]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {

        for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var elr = _step.value;
          cov_1dh3fxgd86.s[59]++;
          var _iteratorNormalCompletion4 = true;
          var _didIteratorError4 = false;
          var _iteratorError4 = undefined;

          try {
            for (var _iterator4 = $scope.listData[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
              var _el = _step4.value;
              cov_1dh3fxgd86.s[60]++;

              if ((cov_1dh3fxgd86.b[14][0]++, elr.IDTYPE_RAPPORT == _el.CODE) && (cov_1dh3fxgd86.b[14][1]++, elr.ANNEE_RAPPORT == _el.year) && (cov_1dh3fxgd86.b[14][2]++, elr.NUM_PERIODE == _el.num)) {
                cov_1dh3fxgd86.b[13][0]++;
                cov_1dh3fxgd86.s[61]++;

                angular.extend(_el, elr);
              } else {
                cov_1dh3fxgd86.b[13][1]++;
              }cov_1dh3fxgd86.s[62]++;
              z;
            }
          } catch (err) {
            _didIteratorError4 = true;
            _iteratorError4 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion4 && _iterator4.return) {
                _iterator4.return();
              }
            } finally {
              if (_didIteratorError4) {
                throw _iteratorError4;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_1dh3fxgd86.s[63]++;
      if ((cov_1dh3fxgd86.b[16][0]++, data) && (cov_1dh3fxgd86.b[16][1]++, data.length > 0)) {
        cov_1dh3fxgd86.b[15][0]++;
        cov_1dh3fxgd86.s[64]++;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = $scope.listData_reportType_bksb[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var el = _step2.value;

            var dataEndDate = (cov_1dh3fxgd86.s[65]++, data.map(function (i) {
              cov_1dh3fxgd86.f[9]++;
              cov_1dh3fxgd86.s[66]++;
              return i.DATE_RELLE_RAPPORT;
            }));
            cov_1dh3fxgd86.s[67]++;
            $scope.listData.push({
              LIBELLE_TYPE: el.LIBELLE_TYPE,
              IDACTEURS: el.IDACTEURS,
              CODE: el.id,
              type: 1
            });

            cov_1dh3fxgd86.s[68]++;
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
              for (var _iterator3 = $deltadate.getPeriods(_this.choise.begin, _this.choise.end, parseInt(el.PERIODE_RAPPORT, 10))[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                var elp = _step3.value;

                var dp = (cov_1dh3fxgd86.s[69]++, angular.copy(elp.end));
                cov_1dh3fxgd86.s[70]++;
                dp.setDate(dp.getDate() + parseInt(el.DELAIS_PROD, 10));
                var dpFormat = (cov_1dh3fxgd86.s[71]++, $deltadate.format(dp, 'yyyy-mm-dd'));
                cov_1dh3fxgd86.s[72]++;
                console.log("check date conditions", {
                  beginDate: new Date(beginDate),
                  endDate: new Date(endDate),
                  dp: dp,
                  dpFormat: dpFormat,
                  beginCheck: new Date(beginDate) > new Date(dpFormat),
                  endCheck: new Date(endDate) < new Date(dpFormat),
                  condition: (cov_1dh3fxgd86.b[17][0]++, new Date(beginDate) > new Date(dpFormat)) && (cov_1dh3fxgd86.b[17][1]++, new Date(endDate) < new Date(dpFormat))
                });

                cov_1dh3fxgd86.s[73]++;
                if ((cov_1dh3fxgd86.b[19][0]++, new Date(beginDate) > new Date(dpFormat)) && (cov_1dh3fxgd86.b[19][1]++, new Date(endDate) < new Date(dpFormat))) {
                  cov_1dh3fxgd86.b[18][0]++;
                  cov_1dh3fxgd86.s[74]++;

                  continue;
                } else {
                  cov_1dh3fxgd86.b[18][1]++;
                }
                cov_1dh3fxgd86.s[75]++;
                $scope.listData.push({
                  period: elp.label,
                  real: null,
                  LIBELLE_TYPE: el.LIBELLE_TYPE,
                  IDACTEURS: el.IDACTEURS,
                  CODE: el.id,
                  type: 2,
                  year: elp.year,
                  prevu: dp,
                  num: elp.index,
                  IDRAPPORTS: 0
                });
              }

              // ! set empty list if no data
            } catch (err) {
              _didIteratorError3 = true;
              _iteratorError3 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion3 && _iterator3.return) {
                  _iterator3.return();
                }
              } finally {
                if (_didIteratorError3) {
                  throw _iteratorError3;
                }
              }
            }

            cov_1dh3fxgd86.s[76]++;
            if ($scope.listData.filter(function (i) {
              cov_1dh3fxgd86.f[10]++;
              cov_1dh3fxgd86.s[77]++;
              return i.type == 2;
            }).length == 0) {
              cov_1dh3fxgd86.b[20][0]++;
              cov_1dh3fxgd86.s[78]++;

              $scope.listData = [];
            } else {
              cov_1dh3fxgd86.b[20][1]++;
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      } else {
        cov_1dh3fxgd86.b[15][1]++;
      }

      cov_1dh3fxgd86.s[79]++;
      $scope.isloading = false;
      cov_1dh3fxgd86.s[80]++;
      $scope.cancel();

      cov_1dh3fxgd86.s[81]++;
      if ((cov_1dh3fxgd86.b[22][0]++, $stateParams.params) && (cov_1dh3fxgd86.b[22][1]++, $scope.init)) {
        cov_1dh3fxgd86.b[21][0]++;
        cov_1dh3fxgd86.s[82]++;

        $scope.edit($stateParams.params.item);
        cov_1dh3fxgd86.s[83]++;
        $scope.init = false;
      } else {
        cov_1dh3fxgd86.b[21][1]++;
      }

      // $scope.listData=data;
    });
  };
  cov_1dh3fxgd86.s[84]++;
  $scope.dateRangeFilter = function (item) {
    cov_1dh3fxgd86.f[11]++;
    cov_1dh3fxgd86.s[85]++;

    if (!item.prevu) {
        cov_1dh3fxgd86.b[23][0]++;
        cov_1dh3fxgd86.s[86]++;
        return false;
      } else {
      cov_1dh3fxgd86.b[23][1]++;
    } // Éviter les erreurs si la date est manquante
    var start = (cov_1dh3fxgd86.s[87]++, $scope.choise.begin);
    var end = (cov_1dh3fxgd86.s[88]++, $scope.choise.end);
    var prevuDate = (cov_1dh3fxgd86.s[89]++, new Date(item.prevu));

    cov_1dh3fxgd86.s[90]++;
    return (cov_1dh3fxgd86.b[24][0]++, prevuDate >= start) && (cov_1dh3fxgd86.b[24][1]++, prevuDate <= end);
  };
  cov_1dh3fxgd86.s[91]++;
  $scope.edit = function (item) {
    cov_1dh3fxgd86.f[12]++;
    cov_1dh3fxgd86.s[92]++;

    $scope.invitation = {};
    cov_1dh3fxgd86.s[93]++;
    console.log('item');
    cov_1dh3fxgd86.s[94]++;
    console.log(item);
    cov_1dh3fxgd86.s[95]++;
    if (typeof item == 'undefined') {
      cov_1dh3fxgd86.b[25][0]++;
      cov_1dh3fxgd86.s[96]++;

      return;
    } else {
      cov_1dh3fxgd86.b[25][1]++;
    }
    cov_1dh3fxgd86.s[97]++;
    $scope.choise.editItem = angular.copy(item);
    cov_1dh3fxgd86.s[98]++;
    console.log('choise.editItem');
    cov_1dh3fxgd86.s[99]++;
    console.log($scope.choise.editItem);

    cov_1dh3fxgd86.s[100]++;
    if ((cov_1dh3fxgd86.b[27][0]++, typeof item.real == 'undefined') || (cov_1dh3fxgd86.b[27][1]++, !item.hasOwnProperty('real')) || (cov_1dh3fxgd86.b[27][2]++, angular.isUndefined(item.real)) || (cov_1dh3fxgd86.b[27][3]++, item.real == null)) {
      cov_1dh3fxgd86.b[26][0]++;
      cov_1dh3fxgd86.s[101]++;

      $scope.choise.editItem.real = Date.newDate();
    } else {
      cov_1dh3fxgd86.b[26][1]++;
    }

    cov_1dh3fxgd86.s[102]++;
    $uibModal.open({
      templateUrl: 'app/views/report_calendar/edit.html',
      controller: report_calendarCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
    cov_1dh3fxgd86.s[103]++;
    this.choise.editing = true;
  };
  cov_1dh3fxgd86.s[104]++;
  $scope.cancel = function () {
    cov_1dh3fxgd86.f[13]++;
    cov_1dh3fxgd86.s[105]++;

    this.choise.editItem = null;
    cov_1dh3fxgd86.s[106]++;
    this.choise.editing = false;
    cov_1dh3fxgd86.s[107]++;
    $scope.step = 1;
  };
  cov_1dh3fxgd86.s[108]++;
  $scope.nextEl = function () {
    cov_1dh3fxgd86.f[14]++;

    var Dateparam = (cov_1dh3fxgd86.s[109]++, this.choise.editItem.real);
    cov_1dh3fxgd86.s[110]++;
    if (!Dateparam) {
      cov_1dh3fxgd86.b[28][0]++;
      cov_1dh3fxgd86.s[111]++;

      return;
    } else {
      cov_1dh3fxgd86.b[28][1]++;
    }
    cov_1dh3fxgd86.s[112]++;
    $scope.step = 2;
  };
  cov_1dh3fxgd86.s[113]++;
  $scope.validate = function (fn) {
    cov_1dh3fxgd86.f[15]++;

    var list = (cov_1dh3fxgd86.s[114]++, '0');
    var Dateparam = (cov_1dh3fxgd86.s[115]++, this.choise.editItem.real);
    var rapport = (cov_1dh3fxgd86.s[116]++, this.choise.editItem.IDRAPPORTS);
    var datform = (cov_1dh3fxgd86.s[117]++, $deltadate.format(Dateparam, 'yyyy-mm-dd'));
    var saveData = (cov_1dh3fxgd86.s[118]++, {
      ANNEE_RAPPORT: this.choise.editItem.year,
      DATE_RELLE_RAPPORT: datform,
      IDTYPE_RAPPORT: this.choise.editItem.CODE,
      NUM_PERIODE: this.choise.editItem.num,
      IDRAPPORTS: this.choise.editItem.IDRAPPORTS,
      id: (cov_1dh3fxgd86.b[29][0]++, this.choise.editItem.idP) || (cov_1dh3fxgd86.b[29][1]++, 0)
    });
    cov_1dh3fxgd86.s[119]++;
    $scope.isloading = true;
    cov_1dh3fxgd86.s[120]++;
    $CRUDService.save($scope.PATH, { action: 'MiseAJour', valeur: $filter('json')(saveData), l: $filter('json')(list + ';') }, function (data) {
      cov_1dh3fxgd86.f[16]++;
      cov_1dh3fxgd86.s[121]++;
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = $scope.listData[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var el = _step5.value;
          cov_1dh3fxgd86.s[122]++;

          if ((cov_1dh3fxgd86.b[31][0]++, $scope.choise.editItem) && (cov_1dh3fxgd86.b[31][1]++, $scope.choise.editItem.CODE == el.CODE) && (cov_1dh3fxgd86.b[31][2]++, $scope.choise.editItem.year == el.year) && (cov_1dh3fxgd86.b[31][3]++, $scope.choise.editItem.num == el.num)) {
            cov_1dh3fxgd86.b[30][0]++;
            cov_1dh3fxgd86.s[123]++;

            el.real = Date.newDate(datform);
            cov_1dh3fxgd86.s[124]++;
            el.IDRAPPORTS = rapport;
            cov_1dh3fxgd86.s[125]++;
            break;
          } else {
            cov_1dh3fxgd86.b[30][1]++;
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      cov_1dh3fxgd86.s[126]++;
      $scope.isloading = false;
      cov_1dh3fxgd86.s[127]++;
      $scope.cancel();
      cov_1dh3fxgd86.s[128]++;
      if (fn) {
        cov_1dh3fxgd86.b[32][0]++;
        cov_1dh3fxgd86.s[129]++;

        fn();
      } else {
        cov_1dh3fxgd86.b[32][1]++;
      }
    });
  };
  cov_1dh3fxgd86.s[130]++;
  $deltaReportType.addController($scope, function () {
    cov_1dh3fxgd86.f[17]++;
    cov_1dh3fxgd86.s[131]++;

    $scope.getAll();
  });
});

function report_calendarCtrl_edit($scope, $uibModalInstance, $state, $filter, $CRUDService, FileUploader) {
  cov_1dh3fxgd86.f[18]++;

  var PATH_DOC = (cov_1dh3fxgd86.s[132]++, 'Documents');
  cov_1dh3fxgd86.s[133]++;
  $scope.options = {
    path: '/198/',
    action: 'save',
    data: {
      path: null, action: null, newName: null, names: null, name: '/', size: 0,
      previousName: null, dateModified: '2019-12-17T10:47:37.69301+00:00',
      dateCreated: '2019-12-17T13:23:02.2029559+00:00', hasChild: true,
      isFile: false, type: '', id: null, filterPath: '\\', filterId: null,
      targetPath: null, renameFiles: null, uploadFiles: null, caseSensitive: false,
      searchString: null, showHiddenItems: false, data: null, targetData: null, permission: null,
      _fm_id: 'fe_tree_0'
    }
  };

  cov_1dh3fxgd86.s[134]++;
  if ((cov_1dh3fxgd86.b[34][0]++, $scope.choise.editItem) && (cov_1dh3fxgd86.b[34][1]++, $scope.choise.editItem.IDRAPPORTS > 0)) {
    cov_1dh3fxgd86.b[33][0]++;
    cov_1dh3fxgd86.s[135]++;

    $scope.isloading = true;
    cov_1dh3fxgd86.s[136]++;
    $CRUDService.getAll(PATH_DOC, { get: 'single', id: $scope.choise.editItem.IDRAPPORTS }, function (data) {
      cov_1dh3fxgd86.f[19]++;
      cov_1dh3fxgd86.s[137]++;

      $scope.choise.editItem.document = data;
      cov_1dh3fxgd86.s[138]++;
      $scope.typeSelect = null;
      cov_1dh3fxgd86.s[139]++;
      $scope.isloading = false;
    });
  } else {
    cov_1dh3fxgd86.b[33][1]++;
  }
  cov_1dh3fxgd86.s[140]++;
  $scope.selectDocumentType = function (typeSelect) {
    cov_1dh3fxgd86.f[20]++;
    cov_1dh3fxgd86.s[141]++;

    $scope.typeSelect = typeSelect + 1;
  };
  cov_1dh3fxgd86.s[142]++;
  $scope.selectDocument = function (item) {
    cov_1dh3fxgd86.f[21]++;
    cov_1dh3fxgd86.s[143]++;

    $scope.choise.editItem.document = item;
    cov_1dh3fxgd86.s[144]++;
    $scope.choise.editItem.IDRAPPORTS = item.id;
    cov_1dh3fxgd86.s[145]++;
    $scope.typeSelect = null;
  };
  cov_1dh3fxgd86.s[146]++;
  $scope.localValidation = function () {
    cov_1dh3fxgd86.f[22]++;
    cov_1dh3fxgd86.s[147]++;

    $scope.isloading = true;
    cov_1dh3fxgd86.s[148]++;
    $scope.validate($scope.closeModal);
  };
  cov_1dh3fxgd86.s[149]++;
  $scope.closeModal = function () {
    cov_1dh3fxgd86.f[23]++;
    cov_1dh3fxgd86.s[150]++;

    $uibModalInstance.dismiss('cancel');
  };
  cov_1dh3fxgd86.s[151]++;
  $scope.filterDataDoc_bksb__ = function (item, b, c, d) {
    cov_1dh3fxgd86.f[24]++;
    cov_1dh3fxgd86.s[152]++;

    if ((cov_1dh3fxgd86.b[36][0]++, angular.isDefined(item.IDCLASSEURS)) && (cov_1dh3fxgd86.b[36][1]++, $scope.typeSelect == 1)) {
      cov_1dh3fxgd86.b[35][0]++;
      cov_1dh3fxgd86.s[153]++;

      return false;
    } else {
      cov_1dh3fxgd86.b[35][1]++;
    }
    cov_1dh3fxgd86.s[154]++;
    return $scope.filterDataDoc_bksb(item, b, c, d);
  };

  cov_1dh3fxgd86.s[155]++;
  $scope.addnewdocument = function (folder) {
    cov_1dh3fxgd86.f[25]++;
    cov_1dh3fxgd86.s[156]++;

    $state.go('document.edit', { id: 0, origin: $state.current.name, params: { idcl: folder.id, file: '', type: 1, item: angular.copy($scope.choise.editItem), listAllThPhDoc: $scope.listAllThPhDoc } });
  };

  cov_1dh3fxgd86.s[157]++;
  $scope.addnewlink = function (folder) {
    cov_1dh3fxgd86.f[26]++;
    cov_1dh3fxgd86.s[158]++;

    $state.go('document.edit', { id: 0, origin: $state.current.name, params: { idcl: folder.id, file: '', type: 2, item: angular.copy($scope.choise.editItem), listAllThPhDoc: $scope.listAllThPhDoc } });
  };

  var uploader = (cov_1dh3fxgd86.s[159]++, $scope.uploader = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadFile&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + PATH_DOC,
    alias: 'uploadFiles',
    removeAfterUpload: true
  }));
  cov_1dh3fxgd86.s[160]++;
  uploader.onAfterAddingFile = function (fileItem) {
    cov_1dh3fxgd86.f[27]++;
    cov_1dh3fxgd86.s[161]++;

    fileItem.formData = [{ path: '/198/' }, { action: 'save' }, { data: angular.toJson({
        path: null, action: null, newName: null, names: null, name: '/', size: 0,
        previousName: null, dateModified: '2019-12-17T10:47:37.69301+00:00',
        dateCreated: '2019-12-17T13:23:02.2029559+00:00', hasChild: true,
        isFile: false, type: '', id: null, filterPath: '', filterId: null,
        targetPath: null, renameFiles: null, uploadFiles: null, caseSensitive: false,
        searchString: null, showHiddenItems: false, data: null, targetData: null, permission: null,
        _fm_id: 'fe_tree_0'
      }) }];
    cov_1dh3fxgd86.s[162]++;
    fileItem.url = uploader.url; // +"&indic="+fileItem.indic.id;
    cov_1dh3fxgd86.s[163]++;
    $scope.file = fileItem;
    cov_1dh3fxgd86.s[164]++;
    $scope.isloading = true;
    cov_1dh3fxgd86.s[165]++;
    $scope.file.url += '&temp=1&saveRapport_new=__' + $CRUDService.getUser() + '__';
    cov_1dh3fxgd86.s[166]++;
    $scope.file.upload();
  };
  cov_1dh3fxgd86.s[167]++;
  uploader.onSuccessItem = function (fileItem) {
    cov_1dh3fxgd86.f[28]++;
    cov_1dh3fxgd86.s[168]++;

    $scope.isloading = false;
    cov_1dh3fxgd86.s[169]++;
    $state.go('document.edit', { id: 0, origin: $state.current.name, params: { idcl: fileItem.id, file: fileItem.file.name, item: angular.copy($scope.choise.editItem), listAllThPhDoc: $scope.listAllThPhDoc } });
  };
  cov_1dh3fxgd86.s[170]++;
  uploader.onErrorItem = function () {
    cov_1dh3fxgd86.f[29]++;
    cov_1dh3fxgd86.s[171]++;

    $scope.isloading = false;
    cov_1dh3fxgd86.s[172]++;
    $scope.closeModal();
  };
}